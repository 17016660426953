@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@300;400;600&display=swap');

/* Base Layout */
.Home {
  text-align: center;
  margin: 20px;
}

body {
  font-family: 'Assistant', sans-serif;
}

h1, h2, h3 {
  font-family: 'Assistant', sans-serif;
}

.Search-container {
  margin-bottom: 20px;
}

/* Dropdown Menu Styles */
.dropdown-icon {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
}

.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* Theme Toggle Button */
.theme-toggle-button {
  position: fixed;
  top: 10px;
  right: 10px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for theme change */
}

/* Table Highlighting Styles */
tr.high-targets td {
  background-color: rgb(217, 223, 236); /* Desired background color for high targets */
  color: blue; /* Desired text color for high targets */
}

tr.low-targets td {
  background-color: rgb(247, 218, 216); /* Desired background color for low targets */
  color: red; /* Desired text color for low targets */
}

/* Light Theme Styles */
.light {
  background-color: #e7e5e0; /* White background */
  color: #333333; /* Darker text */
  font-family: 'Assistant', sans-serif;
}

.light .theme-toggle-button,
.light .button {
  background-color: #eeeeee;
  color: #333333;
  border: 1px solid #cccccc;
}

.light a,
.light .table {
  color: #434b53;
  font-family: 'Assistant', sans-serif;
}

.light .table thead th {
  background-color: #f8f8f8;
  font-family: 'Assistant', sans-serif;
}

.light .table tbody tr:hover {
  background-color: #f0f0f0;
}

/* Dark Theme Styles */
.dark {
  background-color: #302d2e; /* Very dark grey */
  color: #cccccc; /* Lighter text */
  font-family: 'Assistant', sans-serif;
}

.dark .theme-toggle-button,
.dark .button {
  background-color: #333333;
  color: #cccccc;
  border: 1px solid #444444;
}

.dark a,
.dark .table {
  color: #5c6268; 
  font-family: 'Assistant', sans-serif;
}

.dark .table thead th {
  background-color: #6e6e6e;
  font-family: 'Assistant', sans-serif;
}

.dark .table tbody tr:hover {
  background-color: #1f1e1e;
}

/* Button Styles */
button,
.button {
  background-color: #5b5f69; /* Primary color */
  color: #FFFFFF;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  transition: background-color 0.3s, box-shadow 0.3s;
}

button:hover,
.button:hover {
  background-color: #5a5c5f; /* Darken on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

button:active,
.button:active {
  background-color: #3d3d3f;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2);
}
